<script setup lang="ts"></script>

<template>
  <div
    class="fixed top-0 left-0 bottom-0 right-0 w-full bg-stone-700/70 z-[999]"
  >
    <div class="h-full flex justify-center items-center">
      <FetchLoaderLogo />
    </div>
  </div>
</template>

<style scoped></style>
